



















































@import "../../../styles/news";
.Detail {
  font-size: 12px;

  .tabbarTar {
    position: relative;
    margin-bottom: 2.17%;
    .bannerTabbar {
      width: 100%;
      img {
        width: 100%;
      }
    }
  }
  .wrapper {
    width: 120rem;
    text-align: center;
    margin: 5% auto 0;
    .contentFrame {
      .title {
        font-size: 3.17rem;
        font-family: PingFang-SC-Medium, PingFang-SC;
        font-weight: 500;
        color: #333333;
        line-height: 4.42rem;
        margin-bottom: 4rem;
      }
      .title2 {
        font-size: 2rem;
        text-align: center;
      }
      .text-center {
        font-size: 30px;
        line-height: 6.2rem;
        font-weight: 600;
      }
      .content {
        font-size: 1.8rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        margin-bottom: 2.33rem;
      }
      .contentImg {
        text-align: center;
        img {
          width: 50rem;
          margin-bottom: 2.33rem;
        }
      }
      .contentImg-bor {
        text-align: center;
        img {
          width: 90.8rem;
          margin-bottom: 2.33rem;
        }
      }
      // 个人简历
      .borderC {
        border: 3px solid #8d8d8d;
        margin: 3rem 0;
        padding: 1rem;
        .personal-resume {
          // width: 80%;
          border: 6px dotted #8d8d8d;
          padding: 2rem;
          text-align: left;
          .resumeTitle {
            text-align: center;
            font-size: 3rem;
            margin: 3rem 0;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 200px) and (max-width: 768px){
  .bannerTabbar{
    height: 200px;
  }
  .content{
    .title{
      font-size: 22px;
      margin-bottom: 10px;
    }
  }
}
